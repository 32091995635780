<template>
  <WeContainer header="true" v-bind:actions="actions">
    <WeTableSearch v-if="blog.list && blog.list.length" class="d-none">
      <div slot="detail-search">
        <div class="row mb-3">
          <!-- Left -->
          <div class="col-12 col-lg-6">
            <!-- First Name -->
            <div class="d-block mb-3">
              <WeRowInput
                size="sm"
                name="title"
                label="Blog Başlığı"
                placeholder="Blog Başlığı"
                v-model="filter.title"
              />
            </div>
            <!-- First Name -->
          </div>
          <!-- Left -->

          <!-- Right -->
          <div class="col-12 col-lg-6">
            <!-- date -->
            <div class="d-block mb-3">
              <WeRowInput
                form="date"
                size="sm"
                label="Yayınlanma Tarihi"
                placeholder="Yayınlanma Tarihi"
                v-bind:date-range="true"
                v-model="filter.date"
              />
            </div>
            <!-- date -->
          </div>
          <!-- Right -->
        </div>
      </div>
    </WeTableSearch>
    <WeTable
      v-if="ready"
      v-bind:index="false"
      v-bind:data="blog.list"
      v-bind:columns="columns"
      v-bind:actions="tableActions"
      v-bind:multiLanguage="true"
      v-on:on-action="onAction"
      v-on:on-switch="onSwitch"
      v-on:FilterLanguage="FilterLanguage"
    ></WeTable>
    <div v-else class="position-relative" style="min-height: 200px">
      <WeLoading />
    </div>
  </WeContainer>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "List",
  data() {
    return {
      ready: false,
      actions: [
        {
          text: "Yeni Blog",
          path: "blogs/new",
        },
        {
          text: "Çöp Kutusu",
          class: "btn btn-outline-danger float-md-right",
          icon: "fas fa-trash",
          path: "blogs/trash",
        },
      ],
      tableActions: [
        {
          icon: "fas fa-edit",
          class: "btn-outline-primary",
          action: "edit",
        },
        {
          icon: "fas fa-trash",
          class: "btn-outline-danger ml-2",
          action: "remove",
        },
      ],
      columns: [
        { name: "image", icon: "fas fa-camera", type: "image" },
        { name: "title", th: "Başlık", type: "string" },
        { name: "published_at", th: "Yayınlanma Tarihi", type: "datetime" },
        { name: "is_active", th: "Durum", type: "boolean" },
      ],
      filter: {
        title: null,
        date: {
          start: null,
          end: null,
        },
      },
    };
  },
  methods: {
    ...mapActions("blog", ["getList", "delete", "isActive"]),
    onAction(data) {
      if (data.key == "remove") {
        this.onDelete(data.row, data.index);
      } else if (data.key == "edit") {
        this.onEdit(data.row);
      }
    },
    onEdit(row) {
      this.$router.replace("blogs/detail/" + row.id);
    },
    onDelete(row) {
      let message = "Blog'u silmek istediğinize emin misiniz ?";

      this.$swal({
        title: message,
        html: `Bu işlemi <b class="text-danger">Çöp Kutusu</b> bölümünden geri alabilirsiniz.`,
        icon: "question",
        showCancelButton: true,
        cancelButtonText: "İptal",
        confirmButtonText: "Sil",
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          const checkPermission = this.checkPermission("u");
          if (!checkPermission) {
            permission.alert(this);
          } else {
            this.delete({
              id: row.id,
              onSuccess: (result) => {
                if (result && result.data && result.data.status !== "error") {
                  this.$toast.success("Blog Başarıyla Silindi");
                } else {
                  this.$swal({
                    title: "Blog Silinemedi",
                    text: result.data.message,
                    icon: "error",
                    confirmButtonText: "Tamam",
                  });
                }
              },
            });
          }
        }
      });
    },
    onSwitch(data) {
      const checkPermission = this.checkPermission("u");
      if (!checkPermission) {
        permission.alert(this);
      } else {
        this.isActive({
          form: data,
          onSuccess: (result) => {
            if (result && result.data && result.data.status !== "error") {
              this.$toast.success("Durum Güncellendi");
            } else {
              this.$toast.error("Durum Güncellenemedi");
            }
          },
          onError: (error) => {
            this.$toast.error("Durum Güncellenemedi");
          },
        });
      }
    },
    checkPermission(code) {
      return permission.check("blog", code);
    },
    FilterLanguage(lang){
      this.getList({
        lang: lang,
        onSuccess: (result) => {
          this.ready = true;
        },
      });
    }
  },
  computed: {
    ...mapState(["blog","session"]),
  },
  mounted() {
    this.getList({
      onSuccess: (result) => {
        this.ready = true;
      },
    });
    let locales = this.session?.config["site.available_locales"];
    if(locales != undefined){
      locales = JSON.parse(locales)
      if(locales.length > 1) this.columns.push({ name: "locales", th: "Dil", type: "language"})
    }
  },
};
</script>
